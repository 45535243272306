import React from "react";
import Sam1 from "../../images/sam1.jpeg";
import Sam2 from "../../images/hs3.webp";
import Sam3 from "../../images/sam3.jpeg";
import Sam4 from "../../images/sam4.jpeg";
import Sam5 from "../../images/sam5.jpeg";
import Sam6 from "../../images/hse1.webp";
import Sam7 from "../../images/sam7.jpeg";
import Sam8 from "../../images/hs5.webp";
import Sam9 from "../../images/sam9.jpeg";
import Sam10 from "../../images/sam10.jpeg";
import Sam11 from "../../images/sam11.jpeg";
import Sam12 from "../../images/hse4.webp";
import Sam13 from "../../images/sam11.jpeg";
import Sam14 from "../../images/sam11.jpeg";

export default function Section1() {
  return (
    <div>
      <section>
        <div
          class="container px-4 mx-auto py-10"
          style={{ fontFamily: "Lato" }}
        >
          <div class="mb-4 lg:mb-14 flex flex-wrap items-end justify-between">
            <div class="mb-0 md:mb-0">
            <span class="inline-block text-sm text-amber-600 font-bold uppercase tracking-widest">
                Shivaram Properties
              </span>
              <h2 class=" text-2xl md:text-4xl font-semibold">Our Projects</h2>
            </div>
            {/* <a class="inline-block ml-auto md:ml-0 py-4 px-6 text-white font-medium leading-normal text-md bg-amber-600 hover:bg-indigo-950 rounded transition duration-200" href="/contact">Schedule a Call</a> */}
          </div>
          <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam1}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam2}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam3}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg object-top"
                src={Sam4}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam5}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam6}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam7}
                alt=""
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam8}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam9}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam10}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg object-top"
                src={Sam11}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam12}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam13}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Sam14}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
