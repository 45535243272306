import React from "react";

export default function video() {
  return (
    <div className="flex items-center justify-center w-full p-10 border-r-8 ">
      <iframe
        style={{ borderRadius: "10px" }}
        width="700"
        height="450"
        src="https://www.youtube.com/embed/Ko-fSehtAPg"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>
  );
}
