import React from "react";
import Image from "../../images/faq.jpg";
import { Link } from "react-router-dom";

export default function Section3() {
  return (
    <div>
      {/* <section class="py-12 md:py-24 bg-coolGray-50 overflow-hidden">
  <div class="container mx-auto px-4">
    <h1 class="font-heading text-6xl xs:text-8xl sm:text-10xl tracking-tighter mb-20">Services</h1>
    <div class="max-w-2xl mx-auto xl:max-w-none">
      <div class="flex flex-wrap -mx-1.5">
        <div class="w-full xl:w-1/2 px-1.5 mb-4 xl:mb-0">
          <div class="h-full border rounded-4xl">
            <img class="block mb-16 w-full h-88 rounded-4xl object-cover" src="asitrastudio-assets/services/photo-left.png" alt=""/>
            <div class="px-6 max-w-lg mx-auto pb-16 text-center">
              <span class="relative inline-block mb-3 py-2 px-3 text-sm text-white leading-none bg-black rounded-full transition duration-200">Interior</span>
              <span class="block text-4xl mb-4">Planning strategies</span>
              <p class="mb-8">We follow the RIBA Plan of Work 2020 to ensure an orderly framework and project clarity from the outset. First, we conduct feasibility studies and a project review.</p>
              <a class="group inline-flex items-center leading-none font-medium pb-2 border-b-2 border-black" href="#">
                <span class="mr-4">See all projects</span>
                <span class="group-hover:rotate-45 transform transition duration-100">
                  <svg width="11" height="11" viewbox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.5 1.5L1.5 9.5" stroke="black" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                    <path d="M9.5 8.83571V1.5H2.16429" stroke="black" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path>
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="w-full xl:w-1/2 px-1.5">
          <a class="group block mb-3 border rounded-4xl" href="#">
            <div class="flex flex-wrap justify-between">
              <div class="w-full md:w-7/12 pl-6 sm:pl-12 pr-5 py-0 self-center">
                <div class="max-w-xs py-18">
                  <span class="inline-block mb-3 py-2 px-3 text-sm text-black group-hover:text-white leading-none border-1.5 border-black group-hover:bg-black rounded-full transition duration-200">Interior</span>
                  <span class="block text-4xl mb-4">Return on investment</span>
                  <p>We follow the RIBA Plan of Work 2020 to ensure an orderly framework and project clarity from the outset. First, we conduct</p>
                </div>
              </div>
              <div class="w-full md:w-5/12 md:transform group-hover:translate-x-4 transition duration-500">
                <img class="block w-full h-full rounded-4xl object-cover" src="asitrastudio-assets/services/half-photo-btn2.png" alt=""/>
              </div>
            </div>
          </a>
          <a class="group block border rounded-4xl" href="#">
            <div class="flex flex-wrap justify-between">
              <div class="w-full md:w-7/12 pl-6 sm:pl-12 pr-5 py-0 self-center">
                <div class="max-w-xs py-18">
                  <span class="inline-block mb-3 py-2 px-3 text-sm text-black group-hover:text-white leading-none border-1.5 border-black group-hover:bg-black rounded-full transition duration-200">Interior</span>
                  <span class="block text-4xl mb-4">Return on investment</span>
                  <p>We follow the RIBA Plan of Work 2020 to ensure an orderly framework and project clarity from the outset. First, we conduct</p>
                </div>
              </div>
              <div class="w-full md:w-5/12 md:transform group-hover:translate-x-4 transition duration-500">
                <img class="block w-full h-full rounded-4xl object-cover" src="asitrastudio-assets/services/half-photo-btn1.png" alt=""/>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</section> */}
      <section
        class="py-10 md:py-20 bg-gray-100 overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <div class="block md:hidden w-full">
          <img class="w-60 h-60 mx-auto md:mr-0" src={Image} alt="" />
        </div>
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap -m-4">
            <div class="w-full md:w-1/2 p-4">
              <div class="flex flex-col justify-between h-full">
                <div class="flex-initial mb-10">
                  <div class="md:max-w-lg">
                    <h2 class="font-heading mb-6 text-4xl md:text-5xl lg:text-6xl text-gray-900 font-black">
                      Frequently Asked Questions
                    </h2>
                    <p class="text-gray-500 font-bold">
                      Here are some frequently asked questions (FAQs) that
                      potential customers may have about ShivaRam Properties.
                    </p>
                  </div>
                </div>
                <div class="flex-initial">
                  <div class="flex flex-wrap -m-4">
                    <div class="w-full p-4">
                      <Link class="block p-5 md:p-10 bg-white rounded-3xl">
                        <div class="flex flex-wrap -m-2">
                          <div class="flex-1 p-2">
                            <h3 class="font-heading mb-3 text-xl text-gray-900 font-black">
                              Is Samruddhi Nagara Residential Layout DTCP
                              approved?
                            </h3>
                            <p class="text-gray-500 font-bold">
                              Yes, Samruddhi Nagara Residential Layout is DTCP
                              approved, ensuring the legality and credibility of
                              the project.
                            </p>
                          </div>
                          <div class="w-auto p-2">
                            <svg
                              width="24"
                              height="24"
                              viewbox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.07928 15.8201L12.3093 15.8201L17.9193 15.8201C18.8793 15.8201 19.3593 14.6601 18.6793 13.9801L13.4993 8.80007C12.6693 7.97007 11.3193 7.97007 10.4893 8.80007L8.51928 10.7701L5.30927 13.9801C4.63927 14.6601 5.11928 15.8201 6.07928 15.8201Z"
                                fill="#6B7280"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div class="w-full p-4">
                      <Link class="block  p-5 md:p-10 bg-white rounded-3xl">
                        <div class="flex flex-wrap -m-2">
                          <div class="flex-1 p-2">
                            <h3 class="font-heading text-xl text-gray-900 font-black">
                              Where is Samruddhi Nagara Residential Layout
                              located?
                            </h3>
                          </div>
                          <div class="w-auto p-2">
                            <svg
                              width="24"
                              height="24"
                              viewbox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.9207 8.17993L11.6907 8.17993H6.08072C5.12072 8.17993 4.64073 9.33993 5.32073 10.0199L10.5007 15.1999C11.3307 16.0299 12.6807 16.0299 13.5107 15.1999L15.4807 13.2299L18.6907 10.0199C19.3607 9.33993 18.8807 8.17993 17.9207 8.17993Z"
                                fill="#6B7280"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </Link>
                    </div>
                    <div class="w-full p-4">
                      <Link class="block  p-5 md:p-10 bg-white rounded-3xl">
                        <div class="flex flex-wrap -m-2">
                          <div class="flex-1 p-2">
                            <h3 class="font-heading text-xl text-gray-900 font-black">
                              What are the amenities available in Samruddhi
                              Nagara Residential Layout?
                            </h3>
                          </div>
                          <div class="w-auto p-2">
                            <svg
                              width="24"
                              height="24"
                              viewbox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.9207 8.17993L11.6907 8.17993H6.08072C5.12072 8.17993 4.64073 9.33993 5.32073 10.0199L10.5007 15.1999C11.3307 16.0299 12.6807 16.0299 13.5107 15.1999L15.4807 13.2299L18.6907 10.0199C19.3607 9.33993 18.8807 8.17993 17.9207 8.17993Z"
                                fill="#6B7280"
                              ></path>
                            </svg>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="hidden md:block w-1/2 p-4">
              <img class="mx-auto md:mr-0 rounded-md" src={Image} alt="" />
            </div>
            {/* ------------------------------------------------------mobile---------------------------------------------- */}
          </div>
        </div>
      </section>
    </div>
  );
}
