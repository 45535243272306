import React from "react";
import Logo from "../../images/logo.png";
import { Link } from "react-router-dom";

export default function footer() {
  return (
    <div>
      <section
        class="py-5 bg-indigo-950 overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container mx-auto px-4">
          <div class="md:py-10 px-8 bg-indigo-950 shadow-xl rounded-3xl">
            <div class="flex flex-col justify-center">
              <Link to='/' class="inline-block max-w-max mx-auto mb-10">
                <img className="h-16" src={Logo} alt="" />
              </Link>
              <ul class="-m-6 mb-6 text-center">
                <li class="inline-flex p-2 md:p-6">
                  <Link to='/'
                    class="inline-block text-white hover:text-amber-600 font-bold text-lg"
                 
                  >
                    Home
                  </Link>
                </li>
                <li class="inline-flex p-2 md:p-6">
                  <Link to='/about'
                    class="inline-block text-white hover:text-amber-600 font-bold text-lg"
                  >
                    About
                  </Link>
                </li>
                <li class="inline-flex p-2 md:p-6">
                  <Link to='/projects'
                    class="inline-block text-white hover:text-amber-600 font-bold text-lg"
                  >
                    Projects
                  </Link>
                </li>
                <li class="inline-flex p-2 md:p-6">
                  <Link to ='/gallery'
                    class="inline-block text-white hover:text-amber-600 font-bold text-lg"
                  >
                    Gallery
                  </Link>
                </li>
                <li class="inline-flex p-2 md:p-6">
                  <Link to='/contact'
                    class="inline-block text-white hover:text-amber-600 font-bold text-lg"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            
              <p class="text-center font-bold text-white">
                <span>&#x24B8; Copyright. All rights reserved by </span>
                <Link to='/' class="text-amber-600">
                  Shivaram Properties
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
