import React from "react";
import Image1 from '../../images/hs1.webp'
import Image2 from '../../images/hs2.webp'
import Image3 from '../../images/hs3.webp'
import Image4 from '../../images/hs4.webp'
import Image5 from '../../images/hs5.webp'
import Image6 from '../../images/hse1.webp'
import Image7 from '../../images/hse2.webp'
import Image8 from '../../images/hse3.webp'
import Image9 from '../../images/hse4.webp'
import { Link } from "react-router-dom";

export default function Section3() {
  return (
    <div>
      <section>
        <div
          class="container px-4 mx-auto py-10"
          style={{ fontFamily: "Lato" }}
        >
          <div class="mb-6 lg:mb-14 flex flex-wrap items-end justify-between">
            <div class="mb-4 md:mb-0">
            <span class="inline-block text-sm text-amber-600 font-bold uppercase tracking-widest">
                Shivaram Properties
              </span>
              <h2 class="mt-2 text-2xl md:text-4xl font-semibold">
            Completed Residential Projects
              </h2>
            </div>
            <Link to='/contact'
              class="inline-block ml-auto md:ml-0 py-4 px-6 text-white font-medium leading-normal text-md bg-amber-600 hover:bg-indigo-950 rounded-lg transition duration-200"
            >
             Call Now
            </Link>
          </div>
          <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Image3}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg object-top"
                src={Image2}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Image4}
                alt=""
              />
            </div>
          </div>
          <div class="flex flex-wrap -mx-3">
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Image1}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg object-top"
                src={Image8}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Image6}
                alt=""
              />
            </div>  <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Image7}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Image5}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/3 p-3">
              <img
                class="w-full h-72 object-cover rounded-lg"
                src={Image9}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
