import React from "react";
import Icon1 from "../../images/wide.png";



export default function Section2() {
  return (
    <div>
      <section
        class="py-8 md:py-16 overflow-hidden bg-indigo-950"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container px-4 mx-auto">
          <div class="mb-20 max-w-2xl mx-auto text-center">
            <h2 class="font-heading mb-4 text-4xl md:text-6xl text-amber-600">
              List features with icons
            </h2>
            <p class="text-xl md:text-2xl tracking-tight text-white">
              Here&rsquo;s a catchy tagline that supports the headline above
              while adding a little extra information.
            </p>
          </div>
          <div class="flex flex-wrap -m-8">
            <div class="w-full md:w-1/4 md:p-8">
              <div class="w-64 mx-auto text-center">
                <img class="mx-auto mb-7 w-16" src={Icon1} alt="" />
                <p class="text-xl md:text-2xl font-medium tracking-tight text-white">
                  Wide Roads
                </p>
              </div>
            </div>
            <div class="w-full md:w-1/4 p-8">
              <div class="w-64 mx-auto text-center">
                <img class="mx-auto mb-7 w-16" src={Icon1} alt="" />
                <p class="text-xl md:text-2xl font-medium tracking-tight text-white">
                  Street Light
                </p>
              </div>
            </div>
            <div class="w-full md:w-1/4 md:p-8">
              <div class="w-64 mx-auto text-center">
                <img class="mx-auto mb-7 w-16" src={Icon1} alt="" />
                <p class="text-xl md:text-2xl font-medium tracking-tight text-white">
                  Electricity Connection for Individual Sites
                </p>
              </div>
            </div>
            <div class="w-full md:w-1/4 p-8">
              <div class="w-64 mx-auto text-center">
                <img class="mx-auto mb-7 w-16" src={Icon1} alt="" />
                <p class="text-xl md:text-2xl font-medium tracking-tight text-white">
                  UGD Connection for Individual Sites
                </p>
              </div>
            </div>
          </div>
          <div className="md:pt-24">
            <div class="flex flex-wrap -m-8">
              <div class="w-full md:w-1/4 p-8">
                <div class="w-64 mx-auto text-center">
                  <img class="mx-auto mb-7 w-16" src={Icon1} alt="" />
                  <p class="text-xl md:text-2xl font-medium tracking-tight text-white">
                    Water Connection for Individual Sites
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/4 md:p-8">
                <div class="w-64 mx-auto text-center">
                  <img class="mx-auto mb-7 w-16" src={Icon1} alt="" />
                  <p class="text-xl md:text-2xl font-medium tracking-tight text-white">
                    Undergroud Drainage
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/4 p-8">
                <div class="w-64 mx-auto text-center">
                  <img class="mx-auto mb-7 w-16" src={Icon1} alt="" />
                  <p class="text-xl md:text-2xl font-medium tracking-tight text-white">
                    Avenue plantation
                  </p>
                </div>
              </div>
              <div class="w-full md:w-1/4 md:p-8 mb-10 md:mb-0 ">
                <div class="w-64 mx-auto text-center">
                  <img class="mx-auto mb-7 w-16" src={Icon1} alt="" />
                  <p class="text-xl md:text-2xl font-medium tracking-tight text-white">
                    Gated Community
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
