import React from "react";
import Image from "../../images/home2.jpeg";
import { Link } from "react-router-dom";

export default function section2() {
  return (
    <div>
      <section
        class="py-6 md:py-24 sm:px-24 bg-indigo-950  overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container mx-auto px-4">
          <div class="max-w-7xl mx-auto">
            <div class="flex flex-wrap -m-8 mb-10">
              <div class="w-full md:w-1/2 p-8">
                <div class="md:max-w-lg">
                  <h2 class="font-heading mb-3 md:mb-6 text-3xl md:text-5xl text-gray-100 font-black text-center md:text-left">
                    Samruddhi Nagara
                  </h2>
                  <p class="md:mb-8 text-lg md:text-xl text-gray-300 font-medium">
                    "Exciting real estate opportunity in Hassan! Samruddi Nagara
                    Residential Layout, DTCP approved, 2-acre land in
                    Doddakondagula. Limited offer on affordable sites!
                    Amenities: Wide roads, water & sewage connections, parks,
                    jogging track, avenue plantation, site fencing, modern
                    street lights. Overlooks Ring Road. Bank loan available.
                    Zero brokerage fee. Dimensions: 2030, 2530, 3040, 3045,
                    30*50."
                  </p>
                  <div class="flex flex-wrap -m-2">
                    <div class="w-full md:w-auto p-4 md:p-2">
                      <Link
                        class="block w-full px-8 py-2 md:py-3.5 text-lg text-center text-white font-bold bg-amber-600 hover:bg-white hover:text-gray-900 focus:ring-4 focus:ring-blue-200 rounded-3xl"
                    
                      >
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>

              <div class="hidden md:block w-full md:w-1/2 p-0 md:p-8 px-8">
                <iframe
                  style={{ borderRadius: "10px" }}
                  width="600"
                  height="390"
                  src="https://www.youtube.com/embed/Ko-fSehtAPg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="block md:hidden w-full md:w-1/2 p-0 md:p-8 px-8">
                <iframe
                  style={{ borderRadius: "10px" }}
                  width="358"
                  height="280"
                  src="https://www.youtube.com/embed/Ko-fSehtAPg"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
            {/* ----------------------------------------------------------------------------------------------------- */}
            <div class="p-8 md:p-12 bg-white rounded-3xl">
              <div class="flex flex-wrap -m-8">
                <div class="w-full md:w-1/3 p-4 md:p-8">
                  <div class="flex flex-wrap -m-3">
                    <div class="w-auto md:w-full lg:w-auto p-3">
                      <div class="flex items-center justify-center w-12 h-12 bg-indigo-950 rounded-xl">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z"
                            fill="#3B82F6"
                          ></path>
                          <path
                            d="M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z"
                            fill="#3B82F6"
                          ></path>
                          <path
                            d="M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z"
                            fill="#3B82F6"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div class="flex-1 p-3">
                      <h3 class="font-heading mb-2 text-xl text-indigo-950 font-black">
                        Prime Location
                      </h3>
                      <p class="text-sm text-gray-500 font-bold">
                        Doddakondagula, Hassan, adjacent to Proposed Outer Ring
                        Road
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/3 p-4 md:p-8">
                  <div class="flex flex-wrap -m-3">
                    <div class="w-auto md:w-full lg:w-auto p-3">
                      <div class="flex items-center justify-center w-12 h-12 bg-indigo-950 rounded-xl">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z"
                            fill="#3B82F6"
                          ></path>
                          <path
                            d="M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z"
                            fill="#3B82F6"
                          ></path>
                          <path
                            d="M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z"
                            fill="#3B82F6"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div class="flex-1 p-3">
                      <h3 class="font-heading mb-2 text-xl text-gray-900 font-black">
                        Modern Amenities
                      </h3>
                      <p class="text-sm text-gray-500 font-bold">
                        Wide roads, play areas, Parks, jogging tracks, and site
                        fencing.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full md:w-1/3 p-4 md:p-8">
                  <div class="flex flex-wrap -m-3">
                    <div class="w-auto md:w-full lg:w-auto p-3">
                      <div class="flex items-center justify-center w-12 h-12 bg-indigo-950 rounded-xl">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14 16C14 17.77 13.23 19.37 12 20.46C10.94 21.42 9.54 22 8 22C4.69 22 2 19.31 2 16C2 13.9753 3.01397 12.1814 4.5554 11.0973C4.80358 10.9228 5.1393 11.0422 5.27324 11.3145C6.21715 13.2332 7.95419 14.6699 10.02 15.23C10.65 15.41 11.31 15.5 12 15.5C12.4872 15.5 12.9539 15.4538 13.4074 15.3687C13.6958 15.3147 13.9828 15.4995 13.9955 15.7926C13.9985 15.8621 14 15.9314 14 16Z"
                            fill="#3B82F6"
                          ></path>
                          <path
                            d="M18 8C18 8.78 17.85 9.53 17.58 10.21C16.89 11.95 15.41 13.29 13.58 13.79C13.08 13.93 12.55 14 12 14C11.45 14 10.92 13.93 10.42 13.79C8.59 13.29 7.11 11.95 6.42 10.21C6.15 9.53 6 8.78 6 8C6 4.69 8.69 2 12 2C15.31 2 18 4.69 18 8Z"
                            fill="#3B82F6"
                          ></path>
                          <path
                            d="M22 16C22 19.31 19.31 22 16 22C15.2555 22 14.5393 21.8643 13.8811 21.6141C13.5624 21.4929 13.503 21.0851 13.7248 20.8262C14.8668 19.4938 15.5 17.786 15.5 16C15.5 15.66 15.47 15.32 15.42 15C15.3902 14.8155 15.4844 14.6342 15.6478 14.5437C16.9719 13.8107 18.0532 12.6876 18.727 11.3153C18.8609 11.0427 19.1968 10.923 19.4452 11.0978C20.9863 12.1818 22 13.9755 22 16Z"
                            fill="#3B82F6"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div class="flex-1 p-3">
                      <h3 class="font-heading mb-2 text-xl text-gray-900 font-black">
                        Affordable Investment
                      </h3>
                      <p class="text-sm text-gray-500 font-bold">
                        Limited offer with competitive prices and various site
                        dimensions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
