import React from "react";
import { Link } from "react-router-dom";

export default function section4() {
  return (
    <div>
      <section
        class="py-10 bg-indigo-950 overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container mx-auto px-4">
          <div class="bg-white overflow-hidden rounded-3xl">
            <div class="px-8 py-10">
              <div class="md:max-w-2xl text-center mx-auto">
                <span class="inline-block mb-3 text-sm text-amber-600 font-bold uppercase tracking-widest">
                  Best caption here
                </span>
                <h1 class="font-heading mb-6 text-4xl lg:text-5xl text-gray-900 font-black">
                  Invest in a Growing City!
                </h1>
                <p class="mb-8 text-lg md:text-xl font-bold">
                  Be part of an exciting journey! Invest in our special
                  residential opportunity in a city that's growing fast. Watch
                  your investment thrive with all the new developments happening
                  here. Don't miss out, join us now!
                </p>
                <div class="flex flex-wrap justify-center -m-2">
                  <div class="w-full md:w-auto p-2">
                    <Link
                      class="block w-full px-8 py-3.5 text-lg text-center text-white font-bold bg-amber-600 hover:bg-indigo-950  focus:ring-4 focus:ring-blue-200 rounded-full"
                   
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div class="relative">
              <div class="relative z-20 px-8 max-w-max mx-auto">
                <img src="zanrly-assets/images/headers/video.png" alt="" />
               
              </div>
              <div class="absolute left-0 top-0 w-full h-1/2 bg-gray-100">
                <div class="h-full bg-white rounded-b-3xl"></div>
              </div>
              <div class="absolute left-0 bottom-0 w-full h-1/2 bg-gray-100"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
