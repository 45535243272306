import React from "react";
import Image from "../../images/post-05.jpg";

export default function Section1() {
  return (
    <div>
      <section
        class="py-8 lg:py-20 sm:px-24 bg-white overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container px-4 mx-auto">
          <div class="flex flex-wrap xl:items-center -m-8">
            <div class="hidden md:block w-full md:w-1/2 p-8">
              <img
                class="mx-auto rounded-2xl transform hover:-translate-y-1 transition duration-500 h-96"
                src={Image}
                alt=""
              />
            </div>
            <div class="w-full md:w-1/2 p-8">
              <div class="max-w-xl">
                <h2 class="font-heading mb-8 md:mb-14 text-2xl md:text-3xl font-bold">
                  Samruddhi Nagara Residential Layout - Your Gateway to Serenity
                  and Growth
                </h2>
                <div class="flex flex-wrap -m-6">
                  <div class="w-full p-6">
                    <div class="flex flex-wrap -m-2">
                      <div class="w-auto p-2">
                        <div
                          class="flex items-center justify-center text-3xl text-white font-semibold w-10 md:w-14 h-10 md:h-14 bg-indigo-950 rounded-full"
                          style={{ letterSpacing: "-0.5px" }}
                        >
                          1
                        </div>
                      </div>
                      <div class="flex-1 p-2">
                        <p class="text-lg md:text-xl">
                          Discover a world of possibilities at Samruddhi Nagara
                          Residential Layout, a prestigious and DTCP approved
                          venture nestled in the heart of Doddakondagula.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="w-full p-6">
                    <div class="flex flex-wrap -m-2">
                      <div class="w-auto p-2">
                        <div
                          class="flex items-center justify-center text-3xl text-white font-semibold w-10 md:w-14 h-10 md:h-14 bg-indigo-950 rounded-full"
                          style={{ letterSpacing: "-0.5px" }}
                        >
                          2
                        </div>
                      </div>
                      <div class="flex-1 p-2">
                        <p class="text-lg md:text-xl">
                          Samruddhi Nagara Residential Layout is thoughtfully
                          designed to provide a holistic living experience.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="w-full p-6">
                    <div class="flex flex-wrap -m-2">
                      <div class="w-auto p-2">
                        <div
                          class="flex items-center justify-center text-3xl text-white font-semibold w-10 md:w-14 h-10 md:h-14 bg-indigo-950 rounded-full"
                          style={{ letterSpacing: "-0.5px" }}
                        >
                          3
                        </div>
                      </div>
                      <div class="flex-1 p-2">
                        <p class="text-lg md:text-xl">
                          As the city expands, this well-connected locale is set
                          to become a prime address for those seeking a
                          harmonious blend of urban amenities and tranquil
                          surroundings.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block md:hidden w-full md:w-1/2 p-8">
              <img
                class="mx-auto rounded-2xl transform hover:-translate-y-1 transition duration-500 h-80"
                src={Image}
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
