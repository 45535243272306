import React from "react";
import Section1 from "./section1";

export default function Index() {
  return (
    <div>
      <Section1 />
    </div>
  );
}
