import React from "react";
import { Link } from "react-router-dom";

import Image from "../../images/smk.png";

export default function Section1() {
  return (
    <div>
      <section
        class="pt-8 pb-4 md:pb-16 sm:px-24 bg-white overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container mx-auto px-4">
        <div class=" block md:hidden w-full md:w-1/2 p-2">
              <div class="max-w-max mx-auto md:mr-0 bg-white rounded-3xl">
                <img class="mx-auto" src={Image} alt="" />
              </div>
            </div>
          <div class="flex flex-wrap lg:items-center pb-2 -m-8">
            <div class="w-full md:w-1/2 p-8">
              <div class="md:max-w-lg">
                <span class="inline-block md:mb-3 text-sm text-amber-600 font-bold uppercase">
                  Best caption here
                </span>
                <h1 class="font-heading mb-4 text-3xl md:text-5xl text-gray-900 font-black ">
                  About Us
                </h1>
                <p class="mb-4 font-bold text-gray-600 md:text-xl">
                  At ShivaRam Properties, we're your trusted real estate
                  partner. With a customer-centric approach, we offer a
                  comprehensive range of services, including property
                  documentation, valuation, legal assistance, and more. Our
                  vision is to be a leading real estate service provider,
                  dedicated to your satisfaction and delivering innovative
                  solutions. Join us on this exciting journey and turn your real
                  estate dreams into reality. Get in touch with us today!
                </p>
                <div class="flex flex-wrap mb-11 -m-2">
                  <div class="w-full md:w-auto p-2">
                    <Link
                      class="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-amber-600  focus:ring-4 focus:ring-blue-200 rounded-full"
                    
                    >
                      Get Started
                    </Link>
                  </div>
                  <div class="w-full md:w-auto p-2">
                  <Link
                        to="/video"
                        class="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-purple-900 focus:ring-4 focus:ring-gray-600 rounded-full"
                        href="https://youtu.be/Ko-fSehtAPg"
                      >
                        Watch Video
                      </Link>
                  </div>
                </div>

                {/* <div class="block md:hidden flex flex-wrap -m-4">
                  <div class="w-full md:w-1/2 p-4">
                    <div class="px-8 py-4 h-full bg-white rounded-3xl">
                      <div class="flex flex-col justify-between h-full">
                        <div class="flex-initial">
                          <div class="flex flex-wrap -m-0.5">
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star.svg"
                                alt=""
                              />
                            </div>
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star.svg"
                                alt=""
                              />
                            </div>
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star.svg"
                                alt=""
                              />
                            </div>
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star.svg"
                                alt=""
                              />
                            </div>
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <p class="font-medium text-gray-400">
                            <span class="text-gray-900 font-bold">4.2/5</span>
                            <span> (45k Reviews)</span>
                          </p>
                        </div>
                        <div class="flex-initial">
                          <img
                            src="zanrly-assets/images/headers/trustpilot.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-1/2 p-4">
                    <div class="px-8 py-4 h-full bg-white rounded-3xl">
                      <div class="flex flex-col justify-between h-full">
                        <div class="flex-initial">
                          <div class="flex flex-wrap -m-0.5">
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star2.svg"
                                alt=""
                              />
                            </div>
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star2.svg"
                                alt=""
                              />
                            </div>
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star2.svg"
                                alt=""
                              />
                            </div>
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star2.svg"
                                alt=""
                              />
                            </div>
                            <div class="w-auto p-0.5">
                              <img
                                src="zanrly-assets/images/headers/star2.svg"
                                alt=""
                              />
                            </div>
                          </div>
                          <p class="font-medium text-gray-600">
                            <span class="text-gray-900 font-bold">4.1/5</span>
                            <span> (18k Reviews)</span>
                          </p>
                        </div>
                        <div class="flex-initial">
                          <img
                            src="zanrly-assets/images/headers/capterra.svg"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>  */}

               

              </div>
            </div>
            <div class=" hidden md:block w-full md:w-1/2 p-8">
              <div class="max-w-max mx-auto md:mr-0 bg-white rounded-3xl">
                <img class="mx-auto" src={Image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
