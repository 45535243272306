import React from "react";
import Image from "../../images/home.jpeg";
import { Link } from "react-router-dom";

export default function Section1() {
  return (
    <div>
      <section
        class="hidden md:block sm:px-24 pb-4 bg-indigo-950 overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center -m-4">
            <div class="w-96 md:w-[450px] p-4">
              <img class="mx-auto h-auto rounded-2xl" src={Image} alt="" />
            </div>
            <div class="w-full md:flex-1 p-4">
              <div class="flex flex-col justify-center items-center p-8 h-full text-center bg-indigo-900   rounded-3xl">
                <div class="max-w-lg">
                  <span class="inline-block mb-3 text-sm text-amber-600 font-bold uppercase tracking-widest">
                    Best caption here
                  </span>
                  <h1 class="font-heading mb-6 text-5xl text-white font-black ">
                    ShivaRam Property Solutions Pvt. Ltd.
                  </h1>
                  <p class="mb-8 text-xl text-gray-300 font-bold text-justify">
                    ShivaRam Property Solutions Pvt. Ltd. aims to be a one-stop
                    solution for various realty needs, offering convenience and
                    expertise to individuals and businesses looking to deal with
                    properties in the area. If I can assist you with anything
                    else or provide further information, please feel free to
                    ask!
                  </p>
                  <div class="flex flex-wrap justify-center -m-2">
                    <div class="w-full md:w-auto p-2">
                      <Link
                        class="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-amber-600 hover:bg-white hover:text-indigo-950 focus:ring-4 focus:ring-blue-200 rounded-full"
                       
                      >
                        Get Started
                      </Link>
                    </div>
                    <div class="w-full md:w-auto p-2">
                      <Link
                        to="/video"
                        class="block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white focus:ring-4 focus:ring-gray-600 rounded-full"
                        href="https://youtu.be/Ko-fSehtAPg"
                      >
                        Watch Video
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ---------------------------------------mobile-------------------------------------------- */}

      <section
        class="block md:hidden sm:px-24 pb-4 bg-indigo-950 overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container mx-auto px-4">
          <div class="flex flex-wrap justify-center -m-4">
            <div class="w-full md:flex-1 p-4">
              <div class="flex flex-col justify-center items-center p-8 h-full text-center bg-indigo-900   rounded-3xl">
                <div class="max-w-lg">
                  <span class="inline-block mb-3 text-sm text-amber-600 font-bold uppercase tracking-widest">
                    Best caption here
                  </span>
                  <h1 class="font-heading mb-4 text-3xl text-white font-black ">
                    ShivaRam Property Solutions Pvt. Ltd.
                  </h1>
                  <p class="mb-6 text-lg text-gray-300 font-bold text-justify">
                    ShivaRam Property Solutions Pvt. Ltd. aims to be a one-stop
                    solution for various realty needs, offering convenience and
                    expertise to individuals and businesses looking to deal with
                    properties in the area. If I can assist you with anything
                    else or provide further information, please feel free to
                    ask!
                  </p>
                  <div class="flex flex-wrap justify-center -m-2">
                    <div class="w-full md:w-auto p-2">
                      <Link
                        class="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-amber-600 hover:bg-white hover:text-indigo-950 focus:ring-4 focus:ring-blue-200 rounded-full"
                  
                      >
                        Get Started
                      </Link>
                    </div>
                    <div class="w-full md:w-auto p-2">
                      <Link
                        to="/video"
                        class="block w-full px-4 py-2.5 text-sm text-center text-gray-900 font-bold bg-white focus:ring-4 focus:ring-gray-600 rounded-full"
                        href="https://youtu.be/Ko-fSehtAPg"
                      >
                        Watch Video
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4">
              <img class=" h-80 w-96 rounded-2xl" src={Image} alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
