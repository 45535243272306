import React from "react";

export default function Contactone() {
  return (
    <div>
      <section
        class="relative py-5 md:py-20 overflow-hidden"
        style={{ fontFamily: "Lato" }}
      >
        <img
          class="absolute top-0 left-0"
          src="saturn-assets/images/contact/light-left-blue.png"
          alt=""
        />
        <img
          class="absolute bottom-0 right-0 -mb-20"
          src="saturn-assets/images/contact/light-orange-right.png"
          alt=""
        />
        <div class="relative container px-4 mx-auto">
          <div class="max-w-7xl mx-auto">
            <div class="max-w-2xl text-center mx-auto mb-8 md:mb-20">
              <span class="inline-block py-1 px-3 mb-4 text-xs font-semibold text-amber-600 bg-amber-50 rounded-full">
                READY TO SUPPORT US
              </span>
              <h1 class="font-heading text-5xl xs:text-6xl font-bold text-gray-900 mb-4">
                <span>Let&rsquo;s stay</span>
                <span class="font-serif italic">connected</span>
              </h1>
              <p class="text-xl text-gray-500 font-semibold">
                We help people to grow their business using saturn ui library
                with professional and powerfull solution.
              </p>
            </div>
            <div class="xs:max-w-sm lg:max-w-none mx-auto">
              <div class="flex flex-wrap items-center -mx-4 mb-16">
                <div class="w-full lg:w-1/3 px-4 mb-8 md:mb-12 lg:mb-0">
                  <div class="flex items-center lg:justify-center">
                    <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-16 sm:w-20 h-16 sm:h-20 rounded-full bg-indigo-950">
                      <svg
                        fill="white"
                        width="50px"
                        height="50px"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M11.748 5.773S11.418 5 10.914 5c-.496 0-.754.229-.926.387S6.938 7.91 6.938 7.91s-.837.731-.773 2.106c.054 1.375.323 3.332 1.719 6.058 1.386 2.72 4.855 6.876 7.047 8.337 0 0 2.031 1.558 3.921 2.191.549.173 1.647.398 1.903.398.26 0 .719 0 1.246-.385.536-.389 3.543-2.807 3.543-2.807s.736-.665-.119-1.438c-.859-.773-3.467-2.492-4.025-2.944-.559-.459-1.355-.257-1.699.054-.343.313-.956.828-1.031.893-.112.086-.419.365-.763.226-.438-.173-2.234-1.148-3.899-3.426-1.655-2.276-1.837-3.02-2.084-3.824a.56.56 0 0 1 .225-.657c.248-.172 1.161-.933 1.161-.933s.591-.583.344-1.27-1.906-4.716-1.906-4.716z" />
                      </svg>
                    </div>
                    <div>
                      <span class="text-lg text-gray-500">Phone</span>
                      <span class="block text-lg font-semibold text-gray-900">
                        +91-9535896639
                      </span>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 px-4 mb-8 md:mb-12 lg:mb-0">
                  <div class="flex items-center lg:justify-center">
                    <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-16 sm:w-20 h-16 sm:h-20 rounded-full bg-indigo-950">
                      <svg
                        width="50px"
                        height="50px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z"
                          stroke="white"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>{" "}
                    </div>
                    <div>
                      <span class="text-lg text-gray-500">Email</span>
                      <span class="block text-lg font-semibold text-gray-900">
                        Shivarampropertysolutions
                        <br />
                        @gmail.com{" "}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="w-full lg:w-1/3 px-4">
                  <div class="flex items-center lg:justify-center">
                    <div class="flex flex-shrink-0 mr-5 sm:mr-8 items-center justify-center p-1 w-16 sm:w-20 h-16 sm:h-20 rounded-full bg-indigo-950">
                      <svg
                        fill="white"
                        width="50px"
                        height="50px"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M49,18.92A23.74,23.74,0,0,0,25.27,42.77c0,16.48,17,31.59,22.23,35.59a2.45,2.45,0,0,0,3.12,0c5.24-4.12,22.1-19.11,22.1-35.59A23.74,23.74,0,0,0,49,18.92Zm0,33.71a10,10,0,1,1,10-10A10,10,0,0,1,49,52.63Z" />
                      </svg>
                    </div>
                    <div>
                      <span class="text-lg text-gray-500">Office</span>
                      <span class="block text-lg font-semibold text-gray-900">
                        Kuvempu nagar, hassan
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="block md:hidden rounded-4xl overflow-hidden">
              <iframe
                class="w-full"
                id="gmap_canvas"
                style={{ height: "250px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.445197497249!2d76.1141968!3d13.012649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba549878cb45867%3A0x7b26d1018cc8a66a!2sShivaram%20property%20solutions%20pvt.%20ltd!5e0!3m2!1sen!2sin!4v1689426361200!5m2!1sen!2sin"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
            <div class="hidden md:block rounded-4xl overflow-hidden">
              <iframe
                class="w-full"
                id="gmap_canvas"
                style={{ height: "500px" }}
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.445197497249!2d76.1141968!3d13.012649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba549878cb45867%3A0x7b26d1018cc8a66a!2sShivaram%20property%20solutions%20pvt.%20ltd!5e0!3m2!1sen!2sin!4v1689426361200!5m2!1sen!2sin"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            </div>
            {/* <div class=" overflow-hidden">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15549.445197497249!2d76.1141968!3d13.012649!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba549878cb45867%3A0x7b26d1018cc8a66a!2sShivaram%20property%20solutions%20pvt.%20ltd!5e0!3m2!1sen!2sin!4v1689426361200!5m2!1sen!2sin" width="1450" height="450" style={{border:'0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>    
        </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}
