import React from 'react'
import { BrowserRouter, Route, Routes,NavLink,Link } from 'react-router-dom'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import ScrollToTop from "react-scroll-to-top"
import Appwrap from './components/global/appwrap'
import Homepage from './components/homepage/index'
import Aboutpage from './components/about/index'
import Contactpage from './components/contact/index'
import Gallerypage from './components/gallery/index'
import Projectpage from './components/projects/index'
import Video from './components/homepage/video'

export default function App() {
  return (
    <div>
       <FloatingWhatsApp
        phoneNumber="+18003093201"
        accountName="Shivaram Properties"
        allowEsc
        allowClickAway
        notification
        notificationSound
        // avatar={Logo}
        
        
      />
         <div>
   

   <ScrollToTop  smooth className="mb-16 pl-2" color ="blue" />
 </div>
 <BrowserRouter>
<Appwrap>
<Routes>
  <Route exact path="/" element={<Homepage/>}/>
  <Route exact path="/about" element={<Aboutpage/>}/>
  <Route exact path="/gallery" element={<Gallerypage/>}/>
  <Route exact path="/contact" element={<Contactpage/>}/>
  <Route exact path="/projects" element={<Projectpage/>}/>
  <Route exact path='/video' element={<Video/>}/>

</Routes>
</Appwrap>
</BrowserRouter>

    </div>
  )
}
