import React, { useLayoutEffect, useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import { Link, NavLink, useParams } from "react-router-dom";
import Logo from "../../images/logo.png";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    id && setIsMenuOpen(false);
  }, [id]);

  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className="sticky top-0  z-50">
      <section
        class=" py-3 md:py-4 bg-indigo-950"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container mx-auto px-4 ">
          <div class="flex items-center justify-between px-0 md:px-6 py-0 bg-indigo-950 shadow-xl rounded-full">
            <div class="w-auto">
              <div class="flex flex-wrap items-center">
                <div class="w-auto hidden md:block">
                  <NavLink to="/">
                    <img className="w-32 pl-4" src={Logo} alt="" />
                  </NavLink>
                </div>
              </div>
            </div>
            <div class="w-auto">
              <div class="flex flex-wrap items-center">
                <div class="w-auto hidden lg:block">
                  <ul class="flex items-center justify-center">
                    <li class="mr-9">
                      <Link
                        to="/"
                        class="inline-block text-lg font-semibold text-white hover:text-amber-600"
                      >
                        Home
                      </Link>
                    </li>
                    <li class="mr-9">
                      <Link
                        to="/about"
                        class="inline-block text-lg font-semibold text-white hover:text-amber-600"
                      >
                        About
                      </Link>
                    </li>
                    <li class="mr-9">
                      <Link
                        to="/projects"
                        class="inline-block text-lg font-semibold text-white hover:text-amber-600"
                      >
                        Residential Projects
                      </Link>
                    </li>

                    <li class="mr-9">
                      <Link
                        to="/gallery"
                        class="inline-block text-lg font-semibold text-white hover:text-amber-600"
                      >
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact"
                        class="inline-block text-lg font-semibold text-white hover:text-amber-600"
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="w-auto">
              <div class="flex flex-wrap items-center">
                <div class="w-auto hidden lg:block">
                  <div class="flex flex-wrap -m-2">
                    <div class="w-full md:w-auto p-2">
                      <Link class="block w-full px-4 py-2.5 text-sm text-center text-white font-bold bg-amber-600 hover:bg-white hover:text-gray-900 focus:ring-4 focus:ring-blue-200 rounded-full">
                        Get Started
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ..................................mobile............................................ */}

        <div className="flex items-center justify-between z-20 md:hidden px-2">
          <button
            aria-label="Open Menu"
            title="Open Menu"
            class="p-2 -mr-1 transition duration-200 rounded focus:outline-none  focus:shadow-outline hover:bg-deep-purple-50 focus:bg-deep-purple-50"
            onClick={() => setIsMenuOpen(true)}
          >
            <svg class="w-5 text-white" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
              />
              <path
                fill="currentColor"
                d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
              />
              <path
                fill="currentColor"
                d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
              />
            </svg>
          </button>

          <div class="lg:hidden fixed top-0 left-0">
            {isMenuOpen && (
              <SidebarWrap
                key="first"
                initial={{ x: -250, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -280, opacity: 1 }}
                transition={{
                  duration: 0.36,
                  type: "tween",
                  ease: "easeInOut",
                }}
              >
                <div class="absolute w-full">
                  <div
                    className="container  lg:w-4/5 block  "
                    style={{ fontFamily: "Lato" }}
                  >
                    <div>
                      <div className="flex gap-0 ">
                        <li class="dropdown inline ml-4  text-start  cursor-pointer    ">
                          <div class="dropdown-menu absolute hidden h-auto mt-3  z-50   ">
                            <ul class="block w-full bg-[#fff] shadow px-6 py-4 text-indigo-800 rounded"></ul>
                          </div>
                        </li>
                      </div>
                    </div>
                  </div>

                  <hr />

                  <MenuWrap>
                    <nav>
                      <ul
                        class="space-y-2 text-left text-gray-900 font-bold"
                        style={{ fontFamily: "Lato" }}
                      >
                        <li className="nav-item pl-2 font-semibold ">
                          <NavLink
                            activeClassName="selected"
                            exact
                            to="/"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Home{" "}
                          </NavLink>
                        </li>
                        <li className="nav-item pl-2 font-semibold ">
                          <NavLink
                            activeClassName="selected"
                            exact
                            to="/about"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            About{" "}
                          </NavLink>
                        </li>

                        <li
                          className="nav-item pl-2 font-semibold"
                          activeClassName="selected"
                        >
                          <NavLink
                            to="/projects"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Residential Projects
                          </NavLink>
                        </li>

                        <li
                          className="nav-item pl-2 font-semibold"
                          activeClassName="selected"
                        >
                          <NavLink
                            to="/gallery"
                            onClick={() => setIsMenuOpen(false)}
                          >
                            Gallery{" "}
                          </NavLink>
                        </li>

                        <li className="nav-item pl-2 font-semibold">
                          <NavLink
                            to="/contact"
                            onClick={() => setIsMenuOpen(false)}
                            activeClassName="selected"
                          >
                            Contact us
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </MenuWrap>
                </div>
              </SidebarWrap>
            )}

            <div>
              <AnimatePresence>
                {isMenuOpen && (
                  <BackdropWrap
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{
                      duration: 0.36,
                      type: "tween",
                      ease: "easeInOut",
                    }}
                    onClick={() => setIsMenuOpen(false)}
                  />
                )}
              </AnimatePresence>
            </div>
          </div>

          <Link to="/">
            <img src={Logo} alt="logo" style={{ height: "60px" }} />
          </Link>
        </div>
      </section>
    </div>
  );
}

const Wrapper = styled.section`
  li > ul {
    transform: translatex(100%) scale(0);
  }
  li:hover > ul {
    transform: translatex(101%) scale(1);
  }
  li > button svg {
    transform: rotate(-90deg);
  }
  li:hover > button svg {
    transform: rotate(-270deg);
  }

  .group:hover .group-hover\:scale-100 {
    transform: scale(1);
  }
  .group:hover .group-hover\:-rotate-180 {
    transform: rotate(180deg);
  }
  .scale-0 {
    transform: scale(0);
  }
  .min-w-32 {
    min-width: 8rem;
  }
`;

const BackdropWrap = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 200vh;
  background-color: #0a0a0a05;
`;

const SidebarWrap = styled(motion.aside)`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100vh;
  background-color: #ffffff;
  z-index: 50;
`;

const MenuWrap = styled.div`
  .dropdown_menu {
    text-align: left;
    /* height: 167px ; */
    max-height: 167px;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;

    svg {
      font-size: 1rem;
      margin-right: 0.2rem;
    }
  }

  #subdroptoggle {
    background-color: white;
    font-size: 0.9rem;
    font-weight: 600;
    &:hover {
      background-color: #88448a;
      color: white;
    }
  }

  ul {
    padding-bottom: 0.7px;
    background-color: #f5f5f5;
    li {
      a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        margin-bottom: 0.1rem;
        transition: 0.3s;

        &:hover {
          background-color: #408080;
          color: white;
        }
      }

      list-style: none;
      background-color: white;
      /* color:; */
      cursor: pointer;

      svg {
        font-size: 1.3rem;
      }

      .selected {
        background-color: #88448a;
        color: white;
        width: 100%;
        display: block;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 0.5rem 0.75rem 0.5rem 0.9rem;
        margin-bottom: 0.3rem;
      }
    }
  }
`;
