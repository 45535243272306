import React from "react";
import Image1 from "../../images/management.png";
import Image2 from "../../images/legal.png";
import Loan from "../../images/loan.png";
import Image3 from "../../images/consult1.png";
import Image4 from "../../images/register.png";
import Image5 from "../../images/property.png";

export default function Section3() {
  return (
    <div>
      <section class="py-4 md:py-10" style={{ fontFamily: "Lato" }}>
        <div class="container px-4 mx-auto">
          <div class="md:mb-6 flex flex-wrap items-end justify-between">
            <div class="w-full md:w-auto mb-4 md:mb-0">
              <span class="inline-block md:mb-2 text-sm text-amber-600 font-bold uppercase tracking-widest">
                Shivaram Properties
              </span>
              <h2 class="font-heading text-2xl md:text-4xl text-gray-900 font-black">
             Our Services
              </h2>
            </div>
          </div>
          <div class="flex flex-wrap -mx-4">
            <div class="w-full md:w-1/3 p-4">
              <img
                class="w-full h-80 object-cover rounded-lg"
                src={Image5}
                alt=""
              />
              <h3 class="mt-4 font-heading md:mb-2 md:text-xl text-xl text-indigo-950 font-black text-center">
                Property Documentation Assistance
              </h3>
            </div>
            <div class="w-full md:w-1/3 p-4">
              <img
                class="w-full h-80 object-cover rounded-lg"
                src={Image2}
                alt=""
              />
              <h3 class="mt-4 font-heading md:mb-2  md:text-xl text-xl text-indigo-950 font-black text-center">
                Legal Assistance for property
              </h3>
            </div>
            <div class="w-full md:w-1/3 p-4">
              <img
                class="w-full h-80 object-cover rounded-lg"
                src={Loan}
                alt=""
              />
              <h3 class="mt-4 font-heading md:mb-2  md:text-xl text-xl text-indigo-950 font-black text-center">
                Loan Assistance for property
              </h3>
            </div>
            <div class="w-full md:w-1/3 p-4">
              <img
                class="w-full h-80 object-cover rounded-lg"
                src={Image3}
                alt=""
              />
              <h3 class="mt-4 font-heading md:mb-2  md:text-xl text-xl text-indigo-950 font-black text-center">
                Property Buying Consultancy
              </h3>
            </div>
            <div class="w-full md:w-1/3 p-4">
              <img
                class="w-full h-80 object-cover rounded-lg"
                src={Image4}
                alt=""
              />
              <h3 class="mt-4 font-heading md:mb-2  md:text-xl text-xl text-indigo-950 font-black text-center">
                Property Registration Assistance
              </h3>
            </div>
            <div class="w-full md:w-1/3 p-4">
              <img
                class="w-full h-80 object-cover rounded-lg"
                src={Image1}
                alt=""
              />
              <h3 class="mt-4 font-heading md:mb-2  md:text-xl text-xl text-indigo-950 font-black text-center">
                Property Management
              </h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
