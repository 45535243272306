import React from "react";
import Image from "../../images/hs3.webp";
import Image1 from "../../images/hs4.webp";
import Image2 from "../../images/samrudhi.webp";
import Image3 from "../../images/enclave.webp";
import { Link } from "react-router-dom";



export default function Section2() {
  return (
    <div>
      <section
        class="py-5 md:py-12 px-4 sm:px-24 bg-indigo-950"
        style={{ fontFamily: "Lato" }}
      >
        <div class="container">
          <div class="inline-block mb-5 md:mb-16">
            <h1 class="text-4xl md:text-6xl font-medium text-white">
              Our Projects
            </h1>
          </div>

          <Link
            class="hidden md:block mb-8 relative rounded-5 overflow-hidden"
         
          >
            <img
              class="block w-full img-fluid rounded-2xl"
              style={{ minHeight: "530px" }}
              src={Image}
              alt=""
            />
            <div class="absolute bottom-0 start-0 p-14">
              <div class="max-w-xl">
                {/* <div class="mb-2">
                  <span class="text-white text-sm font-medium">Part-time</span>
                  <span class="inline-block mx-4">
                    <svg
                      width="3"
                      height="3"
                      viewBox="0 0 3 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.896 1.772C0.896 1.632 0.924 1.50133 0.98 1.38C1.036 1.25867 1.11067 1.15133 1.204 1.058C1.30667 0.955333 1.41867 0.876 1.54 0.82C1.67067 0.764 1.806 0.736 1.946 0.736C2.086 0.736 2.21667 0.764 2.338 0.82C2.46867 0.876 2.58067 0.955333 2.674 1.058C2.77667 1.15133 2.856 1.25867 2.912 1.38C2.968 1.50133 2.996 1.632 2.996 1.772C2.996 2.052 2.89333 2.29933 2.688 2.514C2.48267 2.72867 2.23533 2.836 1.946 2.836C1.65667 2.836 1.40933 2.72867 1.204 2.514C0.998667 2.29933 0.896 2.052 0.896 1.772Z"
                        fill="white"
                      ></path>
                    </svg>
                  </span>
                  <span class="text-white text-sm font-medium">Remote</span>
                </div> */}
              
              </div>
            </div>
          </Link>

          <div class="mb-8 xl:mb-0 block md:hidden">
            <Link class="block">
              <img
                class="block w-full img-fluid mb-6 rounded-2xl"
                style={{ objectFit: "cover", height: "300px" }}
                src={Image}
                alt=""
              />
           
            </Link>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
            <div class="mb-8 xl:mb-0">
              <Link class="block">
                <img
                  class="block w-full img-fluid mb-6 rounded-2xl"
                  style={{ objectFit: "cover", height: "300px" }}
                  src={Image1}
                  alt=""
                />
                {/* <div class="mb-2">
                  <span class="text-dark-light text-sm font-medium text-white">
                    Apartment
                  </span>
                  <span class="inline-block mx-4">
                    <svg
                      width="3"
                      height="3"
                      viewBox="0 0 3 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.896 1.772C0.896 1.632 0.924 1.50133 0.98 1.38C1.036 1.25867 1.11067 1.15133 1.204 1.058C1.30667 0.955333 1.41867 0.876 1.54 0.82C1.67067 0.764 1.806 0.736 1.946 0.736C2.086 0.736 2.21667 0.764 2.338 0.82C2.46867 0.876 2.58067 0.955333 2.674 1.058C2.77667 1.15133 2.856 1.25867 2.912 1.38C2.968 1.50133 2.996 1.632 2.996 1.772C2.996 2.052 2.89333 2.29933 2.688 2.514C2.48267 2.72867 2.23533 2.836 1.946 2.836C1.65667 2.836 1.40933 2.72867 1.204 2.514C0.998667 2.29933 0.896 2.052 0.896 1.772Z"
                        fill="#646464"
                      ></path>
                    </svg>
                  </span>
                  <span class="text-dark-light text-sm font-medium text-white">
                    Girona, Spain
                  </span>
                </div> */}
                <h4 class="text-2xl text-white">
                Haasanamba Nagara Residential Layout
                </h4>
              </Link>
            </div>

            <div class="mb-8 xl:mb-0">
              <Link class="block">
                <img
                  class="block w-full img-fluid mb-6 rounded-2xl"
                  style={{ objectFit: "cover", height: "300px" }}
                  src={Image2}
                  alt=""
                />
                {/* <div class="mb-2">
                  <span class="text-dark-light text-sm font-medium text-white">
                    Apartment
                  </span>
                  <span class="inline-block mx-4">
                    <svg
                      width="3"
                      height="3"
                      viewBox="0 0 3 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.896 1.772C0.896 1.632 0.924 1.50133 0.98 1.38C1.036 1.25867 1.11067 1.15133 1.204 1.058C1.30667 0.955333 1.41867 0.876 1.54 0.82C1.67067 0.764 1.806 0.736 1.946 0.736C2.086 0.736 2.21667 0.764 2.338 0.82C2.46867 0.876 2.58067 0.955333 2.674 1.058C2.77667 1.15133 2.856 1.25867 2.912 1.38C2.968 1.50133 2.996 1.632 2.996 1.772C2.996 2.052 2.89333 2.29933 2.688 2.514C2.48267 2.72867 2.23533 2.836 1.946 2.836C1.65667 2.836 1.40933 2.72867 1.204 2.514C0.998667 2.29933 0.896 2.052 0.896 1.772Z"
                        fill="#646464"
                      ></path>
                    </svg>
                  </span>
                  <span class="text-dark-light text-sm font-medium text-white">
                    Girona, Spain
                  </span>
                </div> */}
                <h4 class="text-2xl text-white">
                Samruddi Nagara Residential Layout

                </h4>
              </Link>
            </div>

            <div class="col-span-1">
              <Link class="block" >
                <img
                  class="block w-full img-fluid mb-6 rounded-2xl"
                  style={{ objectFit: "cover", height: "300px" }}
                  src={Image3}
                  alt=""
                />
                {/* <div class="mb-2">
                  <span class="text-dark-light text-sm font-medium text-white">
                    Apartment
                  </span>
                  <span class="inline-block mx-4">
                    <svg
                      width="3"
                      height="3"
                      viewBox="0 0 3 3"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.896 1.772C0.896 1.632 0.924 1.50133 0.98 1.38C1.036 1.25867 1.11067 1.15133 1.204 1.058C1.30667 0.955333 1.41867 0.876 1.54 0.82C1.67067 0.764 1.806 0.736 1.946 0.736C2.086 0.736 2.21667 0.764 2.338 0.82C2.46867 0.876 2.58067 0.955333 2.674 1.058C2.77667 1.15133 2.856 1.25867 2.912 1.38C2.968 1.50133 2.996 1.632 2.996 1.772C2.996 2.052 2.89333 2.29933 2.688 2.514C2.48267 2.72867 2.23533 2.836 1.946 2.836C1.65667 2.836 1.40933 2.72867 1.204 2.514C0.998667 2.29933 0.896 2.052 0.896 1.772Z"
                        fill="#646464"
                      ></path>
                    </svg>
                  </span>
                  <span class="text-dark-light text-sm font-medium text-white">
                    Girona, Spain
                  </span>
                </div> */}
                <h4 class="text-2xl text-white">
                Haasanamba RC Enclave
                </h4>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
