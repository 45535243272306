import React from "react";

export default function Section4() {
  return (
    <div
      class=" overflow-hidden py-5 md:py-10 px-4 md:px-8 bg-white md:bg-indigo-950"
      style={{ fontFamily: "Lato" }}
    >
      <div class="mb-5 md:mb-14 max-w-xl mx-auto text-center">
        <h2 class="font-semibold text-3xl md:text-5xl text-gray-900 md:text-white">
          Our Location
        </h2>
      </div>

      <div class="block md:hidden rounded-4xl overflow-hidden">
        <iframe
          class="w-full"
          id="gmap_canvas"
          style={{ height: "250px" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.798866793325!2d76.112174!3d13.048471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba54987ae33fa03%3A0xb6d5852f31035e05!2sSamruddi%20Nagara%20Residential%20Layout!5e0!3m2!1sen!2sin!4v1689622985850!5m2!1sen!2sin"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
      <div class="hidden md:block rounded-4xl overflow-hidden">
        <iframe
          class="w-full"
          id="gmap_canvas"
          style={{ height: "500px" }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.798866793325!2d76.112174!3d13.048471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba54987ae33fa03%3A0xb6d5852f31035e05!2sSamruddi%20Nagara%20Residential%20Layout!5e0!3m2!1sen!2sin!4v1689622985850!5m2!1sen!2sin"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
        ></iframe>
      </div>
    </div>
  );
}
