import React from "react";
import Contact from "./contactone";

export default function Index() {
  return (
    <div>
      <Contact />
    </div>
  );
}
