import React from "react";
import Image from "../../images/ban1.jpeg";

export default function () {
  return (
    <div>
      <section>
        <div class="hidden md:block relative">
          <img class="img-fluid w-full object-cover" style={{height:'600px'}} src={Image} alt="" />
         
        </div>

        {/* -----------------------------------mobile---------------------------------------------------------- */}

        <div class="block md:hidden relative">
          <img class="img-fluid w-full h-60 object-cover" src={Image} alt="" />
        </div>
      </section>
    </div>
  );
}
